@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap');

body{
  font-family: 'Nunito', sans-serif;
}

html{
  font-family: 'Nunito', sans-serif;
}

.App {
  text-align: center;
}

.card{
  max-width: 600px;
  margin: auto;
  padding: 30px;
  border: none !important;
}

.card input{
  height: 40px;
  width: 100%;
}


.card .form-control{
  margin-bottom: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dark-bg {
  background-color: #6f7edb;
  color: #ffffff;
}

.light-bg {
  background-color: #ffffff;
}


.bottom {
  font-size: 12px;
  text-align: center;
}

.img-fluid {
  max-width: 40px;
  max-height: 40px;
}

.container input {
  outline: none;
  border: 0px;
  resize: none;
}

.logout svg {
  height: 25px;
  width: 25px;
  color: white;
}

.bottom {
  font-size: 12px;
  text-align: center;
}

.login-card {
  max-width: 1000px !important;
  padding: 10%;
}

.forgot-text {
  color: black;
  text-decoration: none;
}

a {
  text-decoration: none !important;
}

.login-card input {
  margin-bottom: 20px;
  height: 50px;
  outline: none;
}

.login-card button {
  height: 45px;
}

.btn-primary {
  background-color: #6f7edb !important;
  border-color: #6f7edb !important;
  color: #ffffff;
  height: fit-content !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  border: 0px solid !important;
  border-radius: 0px !important;
}

.btn-success {
  background-color: #ffffff !important;
  border-color: #6f7edb !important;
  color: #6f7edb;
}

.social{
  text-align: center;
  padding: 20px;
}

.btn-success span {
  color: #6f7edb;
}

.btn-success span:nth-child(2){
  margin: 15px;
}

.btn-outline-success {
  border-color: #6f7edb !important;
  color: #6f7edb !important;
}

.btn-outline-success:hover {
  background-color: #6f7edb !important;
  color: #ffffff !important;
}

.drop-area {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
}

.drop-area.highlight {
  border-color: #f00;
}

.preview-image {
  max-width: 300px;
  max-height: 300px;
  margin: 20px auto;
}

.message {
  border: 1px solid black;
  border-radius: 25px;
  padding: 0.5rem 25px !important;
}

.message.left {
  border-color: #6f7edb;
  color: #6f7edb;
  width: fit-content !important;
  max-width: 85% !important;
  margin-bottom: 0;
  margin-left: 10px;
}

.message.right {
  background-color: #6f7edb;
  border-color: #6f7edb;
  color: white;
  width: fit-content !important;
  max-width: 85% !important;
  margin-left: auto;
  margin-bottom: 0;
  margin-right: 10px;
}

#burger{
  display: none;
}

.send-btn {
  width: 60px;
  height: 58px;
  border-radius: 50%;
  padding: 20px;
  margin-left: 20px;
}

.sidebar {
  min-width: 250px;
  max-width: 300px;
  background-color: #6f7edb;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.3), 4px 0px 10px rgba(0, 0, 0, 0.2), 6px 0px 15px rgba(0, 0, 0, 0.1);
  z-index: 0;
  justify-content: start;
}

.sidebar .cover{
  padding: 10px;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
}

.sidebar h5 {
  margin-top: 40px;
}

.drag-area {
  height: 200px;
  width: 100%;
  background-color: #afb2b8;
  border: 2px dashed black;
}

.droper {
  margin-top: -132px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.uploader {
  height: 44px;
  width: 175px;
  font-size: 16px;
  border: 0px;
  background: #6f7edb;
  color: white;
  margin-top: 20px;
  margin-left: auto;
}

.list-item {
  background: antiquewhite;
  padding: 15px 25px;
  border: 1px solid black;
  margin-top: 20px;
}

.droper button {
  width: 143px;
  padding: 8px;
  font-size: 15px;
  border-radius: 10px;
  border: 0px;
}

.droper p {
  margin-bottom: 5px;
}

#catcher{
  justify-content: start;
}

.drag-area input {
  height: 100%;
  width: 100%;
  opacity: 0;
}

#closerbtn{
  display: none;
}

.sidebar .logo {
  max-height: 35px;
  margin-top: 5px;
  text-align: center;
}

.sidebar ul {
  padding: 0px 20px;
}

.sidebar ul li {
  font-size: 12px;
  color: white;
  margin-top: 20px;
}

.send-btn img{
  max-width: 25px;
    max-height: 30px;
}

footer div div{
  font-size: 12px;
  padding-bottom: 10px;
}

footer div div a {
  color: #6f7edb;
  font-weight: 500;
}

.logo-text{
  font-weight: 700;
  margin-left: 15px;
}

/* pricing */
.pricing-box{
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  /* border-radius: 25px; */
  /* text-align: left !important; */
}

.pricing-box ul{
  margin-top: 15px;
  padding-left: 5px;
  text-align: left !important;
}

.pricing-box ul li{
  list-style-type: none;
  margin-bottom: 5px;
}

i.fa.fa-check {
  font-size: 10px;
  /* border: 1px solid; */
  padding: 2px;
  color: green;
  /* border-radius: 100%; */
  margin-right: 15px;
}

i.fa.fa-times {
  font-size: 10px;
  /* border: 1px solid; */
  padding: 2px;
  color: red;
  /* border-radius: 100%; */
  margin-right: 15px;
}

.pricing-box h4,h1,h5{
  text-align: center;
}

.pricing-box h1 span.month{
  font-size: 10px;
}

.pricing-box {
  text-align: center;
}

.section1{
  height: 100vh;
  background-color: #ffffff;
}

.section1 .content-full{
  position: absolute;
  /* height: 100vh; */
  width: 100%;
  z-index: 1000;
}

.section1 .content-full h1{
  color: black !important;
  text-align: left;
}

.section1 .content-full .img-fluid{
  min-height: 400px;
}

.section1 .content-full h1{
  font-size: 60px;
  text-align: center;
  color: white !important;
}

.section1 .content-full h1 span{
  /* font-size: 60px; */
  font-weight: 700;
}

.section1::after{
  content: "";
  height: 80vh;
  background-color: #6f7edb;
  position: absolute;
  width: 100%;
  transform: skewY(10.7deg);
}

.section1::before{
  content: "";
  height: 20vh;
  background-color: #6f7edb;
  position: absolute;
  width: 100%;
  /* transform: skewY(10deg); */
}

.section2{
  height: 100vh;
}

.vertical-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.section2 .right-icon{
  min-height: 200px;
}

.section2 h2{
  color: #6f7edb;
  font-size: 50px;
}

.section2 p{
  font-size: 20px;
  color: black;
  margin-top: 20px;
}

.images-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section3{
  height: 100vh;
}

.section3 h2{
  margin-top: 50px;
  text-align: center;
}

.section2 .other-points h5,h6{
  text-align: left !important;
  width: 100%;
}

.section2 .other-points h5{
  color: #6f7edb;
  font-weight: 700;
}

.section2 .other-points h6{
  margin-bottom: 50px;
}

.section2 ul{
  padding-left: 0px !important;
}

.section2 li{
  list-style-type: none;
  font-size: 30px;
}

.section4{
  height: calc(100vh + 10px);
  background-color: #6f7edb;
}

.section4 .testi-card{
  /* width: fit-content; */
  background-color: white;
  /* min-width: 200px; */
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 15px;
}

.section4 .testi-card i{
  font-size: 45px;
  color: #6f7edb !important;
  margin-bottom: 5px;
}

.section4 .testi-card h3{
  font-size: 47px;
  font-weight: 700;
}

.section4 .testi-card p{
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}

.upgrade-btn{
  color: #6f7edb !important;
}

.btn-primary.upgrade-btn{
  border: 1px solid white !important;
  color: white !important;
}


.contact-btn{
  height: 50px !important;
  border-radius: 6px !important;
}

@media screen and (min-width : 768px) {
  input.form-control {
    min-height: 50px !important;
  }
}

@media only screen and (max-width : 767px) {
  .bottom {
    font-size: 8px !important;
  }

  input.form-control {
    max-height: 50px !important;
    min-height: 25px !important;
  }

  .img-fluid {
    max-width: 30px;
    max-height: 30px;
  }

  .light-text {
    font-size: 12px;
  }
}

@media screen and (max-width : 840px) {
  .sidebar{
    display: none;
    position: absolute;
    height: 100vh;
    justify-content: space-between;
  }

  img, .img-fluid{
    max-height: 30px;
  }

  p{
    font-size: 12px;
  }

  .message{
    padding: 0.4rem 15px !important;
  }

  input{
    height: 40px;
    font-size: 15px;
  }

  .send-btn{
    width: 45px;
    height: 40px;
    margin-left: 5px;
  }

  h6{
    font-size: 14px;
  }

  #closerbtn{
    display: block;
  }

  #burger{
    display: block;
  }

  #catcher{
    justify-content: space-between;
  }
}